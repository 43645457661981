<template>
            <iframe  id="checkListFrame" name="checkListFrame" frameborder="0"
                    :src="url" scrolling="auto"
                    style="width: 102%;overflow:auto;height: calc(100vh - 145px);margin: -5px -15px;border: none;"></iframe>
</template>

<script>
  export default {
    name: "monitor",
    data() {
      return {
        url: "https://waterss.ez-info.cn/shanxi/ln/%E8%BF%90%E8%A1%8C%E6%A6%82%E5%86%B5.html"
      };
    },
    created() {
      console.log("this.$router.path", this.$route.path);
      const path = this.$route.path;

    },
    mounted() {
      console.log("mounted............");
      // let self = this
      // window.onload = function () {
      //   self.setIframeHeight(document.getElementById('checkListFrame'));
      // };
    },
    watch: {
      $route: {
        immediate: true,
        handler(to, from) {
          console.log('to',to)
          console.log('from',from)
          const path = to.path
          let url = "";
          if (path.indexOf("/index/monitor/survey") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E8%BF%90%E8%A1%8C%E6%A6%82%E5%86%B5.html";
          } else if (path.indexOf("/index/monitor/monitoring") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E6%B0%B4%E5%8E%82%E7%9B%91%E6%8E%A7.html";
          } else if (path.indexOf("/index/monitor/supervise") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E7%AE%A1%E7%BD%91%E7%9B%91%E6%B5%8B.html";
          } else if (path.indexOf("/index/monitor/supply") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E4%BE%9B%E6%B0%B4%E6%9F%A5%E8%AF%A2.html";
          } else if (path.indexOf("/index/monitor/quality") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E6%B0%B4%E8%B4%A8%E6%9F%A5%E8%AF%A2.html";
          } else if (path.indexOf("/index/monitor/alarm") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E6%8A%A5%E8%AD%A6%E8%AE%B0%E5%BD%95.html";
          } else if (path.indexOf("/index/monitor/contacts") !== -1) {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E6%8A%A5%E8%AD%A6%E8%81%94%E7%B3%BB%E4%BA%BA.html";
          } else {
            url = "https://waterss.ez-info.cn/shanxi/ln/%E8%AE%BE%E5%A4%87%E5%88%97%E8%A1%A8.html";
          }
          this.url = url;
        }
      }
    },
    methods: {
      setIframeHeight(iframe) {
        if (iframe) {
          var iframeWin = iframe.contentWindow || iframe.contentDocument.parentWindow;
          if (iframeWin.document.body) {
            iframe.height = iframeWin.document.documentElement.scrollHeight || iframeWin.document.body.scrollHeight;
          }
        }
      }
    }
  };
</script>

<style scoped>

</style>
